.copyright {
  background-color: var(--transparent-orange);
  font-size: 16px;
  text-align: center;
  padding: 30px;
}

.copyright a {
  color: white;
}
