.draw {
  background-color: var(--orange);
  padding: 100px 8% 70px 8%; 
}

.draw h1 {
  text-align: center;
}

.drawings {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
}

.drawing-container {
  min-width: 300px;
  width: 40%;
  margin: 30px;
  text-align: center;
}

.drawing {
  border: solid black 1px;
  position: relative;
}

.drawing-page {
  width: 100%;
}

.download-icon {
  position: absolute;
}

@media all and (hover: none) {
  .download-icon {
    width: 50px;
    top: 10px;
    right: 10px;
  }
}

@media all and (hover: hover) {
  .download-icon {
    width: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    visibility: hidden;
  }

  .drawing a:hover {
    cursor: pointer;
  }

  .drawing a:hover > .drawing-page {
    opacity: 0.7;
  }

  .drawing a:hover > .download-icon {
    visibility: visible;
  }
}
