.navbar {
  background: rgba(0,0,0,0.7);
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px !important;
  font-size: 24px;
}

.navbar .container {
  width: 97%;
}

.navbar li {
  transition: all .2s;
}

.social-icons {
  top: 12px !important;
}

.social-icon {
  margin-right: 20px;
}

.first-social-icon {
  margin-left: 10px;
}

.last-social-icon {
  margin-left: 5px;
}

.social-icon img {
  transition: all .2s;
}

@media (min-width : 768px) {
  .navbar {
    margin-top: 30px;
  }

  .navbar-right {
    margin-right: 150px;
  }

  .navbar li:hover a {
    color: var(--light-orange) !important;
  }

  .social-icons {
    position: fixed;
    top: 17px;
    right: 25px;
    z-index: 10000;
  }

  .social-icon:hover img {
    transform: translate(0%, -10%);
  }
}

.navbar li:active {
  background-color: transparent;
  font-weight: bold;
}

.navbar li.active a {
  background-color: transparent !important;
  font-weight: bold;
}

.navbar a {
  color: white !important;
  font-weight: 300;
  margin-right: 20px;
}

.navbar-toggle, .collapsed {
  float: left;
  margin-left: 20px;
}

.navbar-collapse {
  text-align: left;
  border: none;
}

.navbar-default .navbar-toggle {
  border-color: white !important;
  background-color: transparent !important;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

@media (max-width : 768px) {
  .social-icons {
    position: fixed;
    top: 17px;
    right: 5px;
    z-index: 10000;
  }
}
