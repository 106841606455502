.gallery {
  background-color: var(--transparent-orange);
  padding-top: 20px;
}

.photo-grid {
  width: 90%;
  margin-top: 100px;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
}

.photo-grid div {
  margin-left: auto;
  margin-right: auto;
}

.gallery-photo {
  border: solid white 1px;
  margin: 10px;
}
