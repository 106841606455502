.section {
  min-height: 101vh;
  font-size: 50px;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 20px;
}
