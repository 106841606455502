.about {
  background-color: var(--orange);
}

.about-image {
  border-radius: 20px;
  border: solid black 1px;
}

.about-image, .kuranda-image {
  max-width: 100%;
}

.bubba-image {
  filter: sepia();
  -webkit-filter: sepia();
}

@media (min-width: 768px) {
  .about-main-container {
    width: 75%;
    margin-top: 100px;
    margin-bottom: 75px;
  }

  .about-container2 {
    clear: left;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  .about-container1 {
    float: left;
    width: 100%;
    position: relative;
    right: 50%;
    display: flex;
    align-items: center;
  }

  .about-col1 {
    float: left;
    width: 46%;
    position: relative;
    left: 52%;
    text-align: center;
  }

  .about-col2 {
    float: left;
    width: 46%;
    position: relative;
    left: 56%;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .about {
    padding-top: 40px;
  }

  .about-main-container {
    width: 75%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .about-container2 {
    margin-bottom: 60px;
  }

  .about-container1 {
    margin-bottom: 60px;
  }

  .about-col1 img {
    margin-top: 30px;
    max-width: 90%;
  }

  .about-col2 img {
    margin-top: 30px;
    max-width: 90%;
  }
}

.about-text {
  text-align: left;
  font-weight: 300;
}
