:root {
  --light-orange: rgb(223, 128, 73);
  --orange: rgb(216, 103, 27);
  --transparent-orange: rgba(216, 103, 27, 0.65);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
  font-size: 30px;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
