.button {
  background-color: transparent;
  color: white;
  font-size: 40px;
  padding: 16px 34px 12px 34px;
  font-weight: 300;
  transition: all .2s;
  border-radius: 0;
  border: solid white 2px;
}

.button-x-small {
  font-size: 24px;
  padding: 12px 28px 10px 28px;
}

.button-small {
  font-size: 35px;
  padding: 16px 32px 12px 32px;
}

.button-orange {
  background-color: var(--light-orange);
}

.button:hover {
  background-color: var(--orange);
  color:  white;
  border: solid white 2px;
}

.button:focus, .button:active {
  outline: none;
}

.button-reverse-orange:hover {
  background-color: var(--light-orange);
}

.max-width-300 {
  max-width: 300px;
}
