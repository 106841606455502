:root {
  --light-orange: rgb(223, 128, 73);
  --orange: rgb(216, 103, 27);
  --transparent-orange: rgba(216, 103, 27, 0.65);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
  font-size: 30px;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section {
  min-height: 101vh;
  font-size: 50px;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 20px;
}

.about {
  background-color: var(--orange);
}

.about-image {
  border-radius: 20px;
  border: solid black 1px;
}

.about-image, .kuranda-image {
  max-width: 100%;
}

.bubba-image {
  filter: sepia();
  -webkit-filter: sepia();
}

@media (min-width: 768px) {
  .about-main-container {
    width: 75%;
    margin-top: 100px;
    margin-bottom: 75px;
  }

  .about-container2 {
    clear: left;
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  .about-container1 {
    float: left;
    width: 100%;
    position: relative;
    right: 50%;
    display: flex;
    align-items: center;
  }

  .about-col1 {
    float: left;
    width: 46%;
    position: relative;
    left: 52%;
    text-align: center;
  }

  .about-col2 {
    float: left;
    width: 46%;
    position: relative;
    left: 56%;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .about {
    padding-top: 40px;
  }

  .about-main-container {
    width: 75%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .about-container2 {
    margin-bottom: 60px;
  }

  .about-container1 {
    margin-bottom: 60px;
  }

  .about-col1 img {
    margin-top: 30px;
    max-width: 90%;
  }

  .about-col2 img {
    margin-top: 30px;
    max-width: 90%;
  }
}

.about-text {
  text-align: left;
  font-weight: 300;
}

.button {
  background-color: transparent;
  color: white;
  font-size: 40px;
  padding: 16px 34px 12px 34px;
  font-weight: 300;
  transition: all .2s;
  border-radius: 0;
  border: solid white 2px;
}

.button-x-small {
  font-size: 24px;
  padding: 12px 28px 10px 28px;
}

.button-small {
  font-size: 35px;
  padding: 16px 32px 12px 32px;
}

.button-orange {
  background-color: var(--light-orange);
}

.button:hover {
  background-color: var(--orange);
  color:  white;
  border: solid white 2px;
}

.button:focus, .button:active {
  outline: none;
}

.button-reverse-orange:hover {
  background-color: var(--light-orange);
}

.max-width-300 {
  max-width: 300px;
}

.donate {
  background-color: var(--transparent-orange);
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.donate-section-container {
  margin-left: 10%;
  margin-right: 10%;
}

.december-intro {
  margin-bottom: 20px;
}

.december-header {
  margin-bottom: 20px;
}

.donate-section-container hr {
  margin: 50px auto;
}

.donate-intro {
  margin-bottom: 50px;
}

.donate-description {
  margin: 50px auto 0;
  font-weight: 300;
  max-width: min(100%, 750px);
}

.donate-option-container {
  margin: 20px auto 50px;
  font-weight: 300;
  max-width: min(100%, 500px);

  a, a:hover {
    color: white;
    font-weight: bold;
  }
}

.draw {
  background-color: var(--orange);
  padding: 100px 8% 70px 8%; 
}

.draw h1 {
  text-align: center;
}

.drawings {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
}

.drawing-container {
  min-width: 300px;
  width: 40%;
  margin: 30px;
  text-align: center;
}

.drawing {
  border: solid black 1px;
  position: relative;
}

.drawing-page {
  width: 100%;
}

.download-icon {
  position: absolute;
}

@media all and (hover: none) {
  .download-icon {
    width: 50px;
    top: 10px;
    right: 10px;
  }
}

@media all and (hover: hover) {
  .download-icon {
    width: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    visibility: hidden;
  }

  .drawing a:hover {
    cursor: pointer;
  }

  .drawing a:hover > .drawing-page {
    opacity: 0.7;
  }

  .drawing a:hover > .download-icon {
    visibility: visible;
  }
}

.copyright {
  background-color: var(--transparent-orange);
  font-size: 16px;
  text-align: center;
  padding: 30px;
}

.copyright a {
  color: white;
}

.gallery {
  background-color: var(--transparent-orange);
  padding-top: 20px;
}

.photo-grid {
  width: 90%;
  margin-top: 100px;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
}

.photo-grid div {
  margin-left: auto;
  margin-right: auto;
}

.gallery-photo {
  border: solid white 1px;
  margin: 10px;
}

.navbar {
  background: rgba(0,0,0,0.7);
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px !important;
  font-size: 24px;
}

.navbar .container {
  width: 97%;
}

.navbar li {
  transition: all .2s;
}

.social-icons {
  top: 12px !important;
}

.social-icon {
  margin-right: 20px;
}

.first-social-icon {
  margin-left: 10px;
}

.last-social-icon {
  margin-left: 5px;
}

.social-icon img {
  transition: all .2s;
}

@media (min-width : 768px) {
  .navbar {
    margin-top: 30px;
  }

  .navbar-right {
    margin-right: 150px;
  }

  .navbar li:hover a {
    color: var(--light-orange) !important;
  }

  .social-icons {
    position: fixed;
    top: 17px;
    right: 25px;
    z-index: 10000;
  }

  .social-icon:hover img {
    -webkit-transform: translate(0%, -10%);
            transform: translate(0%, -10%);
  }
}

.navbar li:active {
  background-color: transparent;
  font-weight: bold;
}

.navbar li.active a {
  background-color: transparent !important;
  font-weight: bold;
}

.navbar a {
  color: white !important;
  font-weight: 300;
  margin-right: 20px;
}

.navbar-toggle, .collapsed {
  float: left;
  margin-left: 20px;
}

.navbar-collapse {
  text-align: left;
  border: none;
}

.navbar-default .navbar-toggle {
  border-color: white !important;
  background-color: transparent !important;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

@media (max-width : 768px) {
  .social-icons {
    position: fixed;
    top: 17px;
    right: 5px;
    z-index: 10000;
  }
}

.carousel-img {
  height: 101vh !important;
  width: 100vw !important;
  object-fit: cover;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.carousel-caption {
  top: 38%;
  left: 10%;
  right: 10%;
}

.carousel-text {
  padding-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.home {
  background-color: var(--orange);
}

@media (max-width : 768px) {
  .carousel-text {
    font-size: 24px;
  }
}

