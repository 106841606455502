.donate {
  background-color: var(--transparent-orange);
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.donate-section-container {
  margin-left: 10%;
  margin-right: 10%;
}

.december-intro {
  margin-bottom: 20px;
}

.december-header {
  margin-bottom: 20px;
}

.donate-section-container hr {
  margin: 50px auto;
}

.donate-intro {
  margin-bottom: 50px;
}

.donate-description {
  margin: 50px auto 0;
  font-weight: 300;
  max-width: min(100%, 750px);
}

.donate-option-container {
  margin: 20px auto 50px;
  font-weight: 300;
  max-width: min(100%, 500px);

  a, a:hover {
    color: white;
    font-weight: bold;
  }
}
