.carousel-img {
  height: 101vh !important;
  width: 100vw !important;
  object-fit: cover;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.carousel-caption {
  top: 38%;
  left: 10%;
  right: 10%;
}

.carousel-text {
  padding-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.home {
  background-color: var(--orange);
}

@media (max-width : 768px) {
  .carousel-text {
    font-size: 24px;
  }
}
